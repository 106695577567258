import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import onboarding from './onboarding';
import store from '../store';
import DashboardLayout from '../layout/DashboardLayout.vue';
import AbstractLayout from '../layout/AbstractRouteView.vue';
import SimpleLayout from '../layout/SimpleLayout.vue';
import DataspacesView from '../views/dataspaces/DataspacesView.vue';
import JoinDatspace from '../views/dataspaces/join/JoinDataspace.vue';
import {
  CHECK_TOKEN,
  GET_KEYCLOAK_REALM,
  LOGOUT
} from '../store/modules/auth/action-types';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'dashboard',
    component: DashboardLayout,
    redirect: '/',
    meta: {
      isProtected: true
    },
    children: [{
      path: '/',
      name: 'maindashboard',
      meta: {
        isProtected: true
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/DashboardView.vue')
    }, {
      path: '/connectors',
      name: 'connectors',
      component: () => import(/* webpackChunkName: "connectors" */ '../views/connectors/ConnectorsView.vue')
    }, {
      path: '/dataspaces',
      name: 'dataspaces',
      component: AbstractLayout, 
      children: [{
        path: '',
        component: () => import(/* webpackChunkName: "dataspaces" */ '../views/dataspaces/DataspacesView.vue'),
      }, {
        path: 'browse',
        name: 'browseDataspaces',
        component: DataspacesView
      }, {
        path: 'join/:id',
        name: 'joinDataspace',
        component: JoinDatspace
      }]
    }, {
      path: '/organizations',
      name: 'organizations',
      component: () => import(/* webpackChunkName: "organizations" */ '../views/organizations/OrganizationsView.vue')
    }]
  }, 
  {
    path: '/onboarding',
    name: 'onboarding',
    component: SimpleLayout,
    children: [{
      path: 'register-organization',
      name: 'registerOrg',
      component: () => import(/* webpackChunkName: "organizations" */ '../views/onboarding/organization/OrganizationOnboarding.vue')
    }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next)=>{
  /* if(to.meta && to.meta.isProtected) {
    store.dispatch(GET_KEYCLOAK_REALM);
    store.dispatch(CHECK_TOKEN, true)
      .then(() => {
        console.log('success');
        next();
      })
      .catch(err => {
        console.log('error');
        console.log(err);
        store.dispatch(LOGOUT);
      });
  } else {
    next();
  }*/
  next();
  
})

export default router
