




























import Vue from 'vue';
import LanguageSelect from '@/components/LanguageSelect/LanguageSelect.vue';

export default Vue.extend({
  name: 'SimpleNavigation',
  components: {
    LanguageSelect
  },
  data() {
    return {
      language: 'en'
    }
  }
});
