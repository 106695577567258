/**
 * This service is use for organization request
 */

import axios from 'axios';
import Util from '../util';
import {INewOrg, IntResponseObj} from '@/common/interfaces/organization/ICreateOrganization';

export default class OrganizationService {
    /**
     * Create a new organization
     * @param organization 
     * @returns object
     */
    static registerNewOrganization(organization: INewOrg) {
      console.log(organization);
      return axios.post(Util.getRestApiUrl('org/register'), organization)
    }
}