import Vue from 'vue';
import {ActionTree} from 'vuex';
import {
  manageOnboardingInt
} from './OrgOnboardingInt';
import {
  SEND_ORGANIZATION_REQUEST
} from './action-types';
import {
  SET_IS_PROGRESS
} from './mutation-types'
import OrganizationService from '@/common/services/OrganizationServices';

export const actions: ActionTree<manageOnboardingInt, {}> = {
  /**
   * Dispatch the post request to create a new organization
   * @param param0 
   */
  [SEND_ORGANIZATION_REQUEST]({commit, state}) {
    commit(SET_IS_PROGRESS, true);
    const {
      orgName,
      orgAddress,
      orgTaxId,
      orgAdminFirstName,
      orgAdminLastName,
      orgAdminRole,
      orgAdminPhoneNo,
      orgAdminEmail,
      orgAdminCity
    } = state.formFields;
    const payload = {
      "name": orgName || '',
      "address": {
        "street": orgAddress.street || '',
        "zip": orgAddress.postCode || '',
        "city": orgAddress.city || ''
      },
      "phone": orgAddress.number || '',
      "taxId": orgTaxId || '',
      "admin": {
        "firstName": orgAdminFirstName || '',
        "lastName": orgAdminLastName || '',
        "email": orgAdminEmail || '',
        "phone": orgAdminPhoneNo || '',
        "role": orgAdminRole || ''
      }
    };
    OrganizationService.registerNewOrganization(payload)
      .then(res => res.data)
      .catch(err => {
        //TODO 
        console.log(err);
      })
      .finally(() => {
        commit(SET_IS_PROGRESS, false);
      });
  }
}
