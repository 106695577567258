import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import roles from './modules/roles';
import manageOrgOnboardingState from '../views/onboarding/store';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    roles,
    manageOrgOnboardingState
  }
})
