















import Vue from 'vue'
export default Vue.extend({
  name: 'TSection',
  props: {
    isHeaderShow: {
      default: true
    }
  } 
})
