





import Vue from 'vue';
import {
  TBadge
} from '@dih/dih-lib-frontend-component';

export default Vue.extend({
  name: 'TileStatus',
  components: {
    TBadge
  }
})
