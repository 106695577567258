



























import Vue from 'vue';
import {IWizardItem} from './IProcessModal';
import TWizardItem from './TWizardItem/TWizardItem.vue';
import {
  TLoadingDots
} from '@dih/dih-lib-frontend-component';

export default Vue.extend({
  components: {
    TWizardItem,
    TLoadingDots
  },
  props: {
    menuItems: {
      type: Array as () => IWizardItem[],
      required: true
    },
    activeItemId: {
      type: String,
      required: true
    },
    isAutosaving: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    save() {
      this.$emit('save');
    },
    updateActiveItemId(itemId: string) {
      this.$emit('updateActiveItemId', itemId);
    }
  }
});
