// storage
export const IS_ROLE_STORAGE_VIEWER = 'IS_ROLE_STORAGE_VIEWER';
export const IS_ROLE_OBJECT_CREATOR = 'IS_ROLE_OBJECT_CREATOR';
// data sources
export const IS_ROLE_SOURCE_READER = 'IS_ROLE_SOURCE_READER';
export const IS_ROLE_SOURCE_CREATOR = 'IS_ROLE_SOURCE_CREATOR';
export const IS_ROLE_SOURCE_UPDATER = 'IS_ROLE_SOURCE_UPDATER';
export const IS_ROLE_SOURCE_ERASER = 'IS_ROLE_SOURCE_ERASER';
// data offers
export const IS_ROLE_OFFER_READER = 'IS_ROLE_OFFER_READER';
export const IS_ROLE_OFFER_CREATOR = 'IS_ROLE_OFFER_CREATOR';
export const IS_ROLE_OFFER_UPDATER = 'IS_ROLE_OFFER_UPDATER';
export const IS_ROLE_OFFER_ERASER = 'IS_ROLE_OFFER_ERASER';
// workspace sources
export const IS_ROLE_WORKSPACE_SOURCE_READER = 'IS_ROLE_WORKSPACE_SOURCE_READER';
export const IS_ROLE_WORKSPACE_SOURCE_CREATOR = 'IS_ROLE_WORKSPACE_SOURCE_CREATOR';
export const IS_ROLE_WORKSPACE_SOURCE_ERASER = 'IS_ROLE_WORKSPACE_SOURCE_ERASER';
// workspace apps
export const IS_ROLE_APPLICATIONS_READER = 'IS_ROLE_APPLICATIONS_READER';
export const IS_ROLE_APPLICATIONS_ACTIVATOR = 'IS_ROLE_APPLICATIONS_ACTIVATOR';
export const IS_ROLE_APPLICATIONS_DEACTIVATOR = 'IS_ROLE_APPLICATIONS_DEACTIVATOR';
export const IS_ROLE_APPLICATIONS_REQUEST = 'IS_ROLE_APPLICATIONS_REQUEST';
// workspace projects
export const IS_ROLE_PROJECT_APPLICATIONS = 'IS_ROLE_PROJECT_APPLICATIONS';
export const IS_ROLE_PROJECT_READER = 'IS_ROLE_PROJECT_READER';
export const IS_ROLE_PROJECT_CREATOR = 'IS_ROLE_PROJECT_CREATOR';
export const IS_ROLE_PROJECT_UPDATER = 'IS_ROLE_PROJECT_UPDATER';
export const IS_ROLE_PROJECT_ERASER = 'IS_ROLE_PROJECT_ERASER';
// organization
export const IS_ROLE_MANAGE_ORGANIZATION = 'IS_ROLE_MANAGE_ORGANIZATION';
export const IS_ROLE_MEMBERS_VIEWER = 'IS_ROLE_MEMBERS_VIEWER';
export const IS_ROLE_MEMBERS_CREATOR = 'IS_ROLE_MEMBERS_CREATOR';
export const IS_ROLE_MEMBERS_ERASER = 'IS_ROLE_MEMBERS_ERASER';
export const IS_ROLE_MEMBERS_UPDATER = 'IS_ROLE_MEMBERS_UPDATER';
// marketplace
export const IS_ROLE_OFFER_PURCHASER = 'IS_ROLE_OFFER_PURCHASER';
