
























import Vue from 'vue';
import TileIcon from '../TileIcon/TileIcon.vue';
import TileStatus from '../TileStatus/TileStatus.vue';

export default Vue.extend({
  name: 'DataspacesTile',
  components: {
    TileIcon,
    TileStatus
  },
  props: {
    tileItem: {
      required: false
    },
    boxType: {
      type: String
    }
  }
});
