import {GetterTree} from 'vuex';
import {
  GET_IS_PROGRESS,
  GET_FORMFIELD_ORG_NAME,
  GET_FORMFIELD_ORG_ADDRESS_CITY,
  GET_FORMFIELD_ORG_ADDRESS_NUMBER,
  GET_FORMFIELD_ORG_ADDRESS_POSTCODE,
  GET_FORMFIELD_ORG_ADDRESS_STREET,
  GET_FORMFIELD_ORG_ADMIN_CITY,
  GET_FORMFIELD_ORG_ADMIN_EMAIL,
  GET_FORMFIELD_ORG_ADMIN_FIRSTNAME,
  GET_FORMFIELD_ORG_ADMIN_LASTNAME,
  GET_FORMFIELD_ORG_ADMIN_PHONENO,
  GET_FORMFIELD_ORG_ADMIN_ROLE,
  GET_FORMFIELD_ORG_TAX_ID
} from './getter-types';
import {
  manageOnboardingInt
} from './OrgOnboardingInt';

export const getters: GetterTree<manageOnboardingInt, {}> = {
  [GET_IS_PROGRESS]: state => state.isReqLoading,
  [GET_FORMFIELD_ORG_NAME]: state => state.formFields.orgName,
  [GET_FORMFIELD_ORG_ADDRESS_STREET]: state => state.formFields.orgAddress.street,
  [GET_FORMFIELD_ORG_ADDRESS_NUMBER]: state => state.formFields.orgAddress.number,
  [GET_FORMFIELD_ORG_ADDRESS_POSTCODE]: state => state.formFields.orgAddress.postCode,
  [GET_FORMFIELD_ORG_ADDRESS_CITY]: state => state.formFields.orgAddress.city,
  [GET_FORMFIELD_ORG_TAX_ID]: state => state.formFields.orgTaxId,
  [GET_FORMFIELD_ORG_ADMIN_FIRSTNAME]: state => state.formFields.orgAdminFirstName,
  [GET_FORMFIELD_ORG_ADMIN_LASTNAME]: state => state.formFields.orgAdminLastName,
  [GET_FORMFIELD_ORG_ADMIN_EMAIL]: state => state.formFields.orgAdminEmail,
  [GET_FORMFIELD_ORG_ADMIN_CITY]: state => state.formFields.orgAdminCity,
  [GET_FORMFIELD_ORG_ADMIN_PHONENO]: state => state.formFields.orgAdminPhoneNo,
  [GET_FORMFIELD_ORG_ADMIN_ROLE]: state => state.formFields.orgAdminRole
}