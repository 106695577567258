



































import Vue from 'vue';
import {
  TModal
} from '@dih/dih-lib-frontend-component';
import TileIcon from '@/components/Tiles/TileIcon/TileIcon.vue';
import TileStatus from '@/components/Tiles/TileStatus/TileStatus.vue';

export default Vue.extend({
  name: 'ToolsInfoModal',
  components: {
    TModal,
    TileIcon,
    TileStatus
  },
  props: {
    isRequestInProgress: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  methods: {
    onRequest() {
      this.$emit('onClickRequest');
    },
    exit() {
      this.$emit('exit');
    }
  }
});
