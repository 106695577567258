import Vue from 'vue'
import i18n from './i18n';
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/styles.scss';
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
