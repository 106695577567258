import {MutationTree} from 'vuex';
import {
  SET_ORG_ADDRESS_CITY,
  SET_ORG_ADDRESS_NUMBER,
  SET_ORG_ADDRESS_POSTCODE,
  SET_ORG_ADDRESS_STREET,
  SET_ORG_ADDRESS_TAXID,
  SET_ORG_NAME,
  SET_ORG_USER_CITY,
  SET_ORG_USER_DEFINE_ROLE,
  SET_ORG_USER_EMAIL,
  SET_ORG_USER_FIRSTNAME,
  SET_ORG_USER_LASTNAME,
  SET_ORG_USER_PHONE_NO
} from './mutation-types';

import {
  manageOnboardingInt
} from './OrgOnboardingInt';

export const mutations: MutationTree<manageOnboardingInt> = {
  [SET_ORG_NAME](state: manageOnboardingInt, value: string) {
    state.formFields.orgName = value;
  },
  [SET_ORG_ADDRESS_NUMBER](state: manageOnboardingInt, value: string) {
    state.formFields.orgAddress.number = value;
  },
  [SET_ORG_ADDRESS_CITY](state: manageOnboardingInt, value: string) {
    state.formFields.orgAddress.city = value;
  },
  [SET_ORG_ADDRESS_POSTCODE](state: manageOnboardingInt, value: string) {
    state.formFields.orgAddress.postCode = value;
  },
  [SET_ORG_ADDRESS_STREET](state: manageOnboardingInt, value: string) {
    state.formFields.orgAddress.street = value;
  },
  [SET_ORG_ADDRESS_TAXID](state: manageOnboardingInt, value: string) {
    state.formFields.orgTaxId = value;
  },
  [SET_ORG_USER_CITY](state: manageOnboardingInt, value: string) {
    state.formFields.orgAdminCity = value;
  },
  [SET_ORG_USER_DEFINE_ROLE](state: manageOnboardingInt, value: string) {
    state.formFields.orgAdminRole = value;
  },
  [SET_ORG_USER_FIRSTNAME](state: manageOnboardingInt, value: string) {
    state.formFields.orgAdminFirstName = value;
  },
  [SET_ORG_USER_EMAIL](state: manageOnboardingInt, value: string) {
    state.formFields.orgAdminEmail = value;
  },
  [SET_ORG_USER_LASTNAME](state: manageOnboardingInt, value: string) {
    state.formFields.orgAdminLastName= value;
  },
  [SET_ORG_USER_PHONE_NO](state: manageOnboardingInt, value: string) {
    state.formFields.orgAdminPhoneNo= value;
  },
};
