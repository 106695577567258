export const GET_IS_PROGRESS = 'GET_IS_PROGRESS';
export const GET_FORMFIELD_ORG_NAME = 'GET_FORMFIELD_ORG_NAME';
export const GET_FORMFIELD_ORG_ADDRESS_STREET = 'GET_FORMFIELD_ORG_ADDRESS_STREET';
export const GET_FORMFIELD_ORG_ADDRESS_NUMBER = 'GET_FORMFIELD_ORG_ADDRESS_NUMBER';
export const GET_FORMFIELD_ORG_ADDRESS_POSTCODE = 'GET_FORMFIELD_ORG_ADDRESS_POSTCODE';
export const GET_FORMFIELD_ORG_ADDRESS_CITY = 'GET_FORMFIELD_ORG_ADDRESS_CITY';
export const GET_FORMFIELD_ORG_TAX_ID = 'GET_FORMFIELD_ORG_TAX_ID';
export const GET_FORMFIELD_ORG_ADMIN_FIRSTNAME = 'GET_FORMFIELD_ORG_ADMIN_FIRSTNAME';
export const GET_FORMFIELD_ORG_ADMIN_LASTNAME = 'GET_FORMFIELD_ORG_ADMIN_LASTNAME';
export const GET_FORMFIELD_ORG_ADMIN_EMAIL = 'GET_FORMFIELD_ORG_ADMIN_EMAIL';
export const GET_FORMFIELD_ORG_ADMIN_CITY = 'GET_FORMFIELD_ORG_ADMIN_CITY';
export const GET_FORMFIELD_ORG_ADMIN_PHONENO = 'GET_FORMFIELD_ORG_ADMIN_PHONENO';
export const GET_FORMFIELD_ORG_ADMIN_ROLE = 'GET_FORMFIELD_ORG_ADMIN_ROLE';