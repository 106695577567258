























import Vue from 'vue';
export default Vue.extend({
  name: 'ContextSwitchItem',
  props: {
    hasDropdownIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isContextSwitchOpen: false
    };
  },
  watch: {
    $route() {
      this.closeWidget();
    }
  },
  methods: {
    closeWidget() {
      if (this.isContextSwitchOpen) {
        this.isContextSwitchOpen = false;
      }
    },
    toggleWidget() {
      this.isContextSwitchOpen = !this.isContextSwitchOpen;
    }
  }
});
