


























































































import Vue from 'vue';
import PageHeading from '@/components/PageHeading/PageHeading.vue';
import TWizardMenu from '@/components/TWizardMenu/TWizardMenu.vue';
import {
  TProcessSection,
  TFormField,
  TSelect
} from '@dih/dih-lib-frontend-component';
import {
  JoinDataspaceStepInt
} from './JoinDataspacesInt';

export default Vue.extend({
  name: 'DashboardView',
  components: {
    PageHeading,
    TWizardMenu,
    TProcessSection,
    TFormField,
    TSelect
  },
  data() {
    return {
      steps: [{
        id: JoinDataspaceStepInt.ABOUT,
        title: 'Basic Info',
        status: 'INCOMPLETE',
        subtitle: 'General information, logo'
      },{
        id: JoinDataspaceStepInt.CONFIG,
        title: 'Configuration',
        status: 'INCOMPLETE',
        subtitle: 'File, API endpoint'
      }],
      activeStepId: JoinDataspaceStepInt.ABOUT,
      createForm: {
        about: {
          name: '',
          connectorCategory: null,
          description: '',
          maintainer: '',
          curator: ''
        },
        configuration: {

        }
      },
      connectorCategories: [{
        id: 1,
        title: 'Connector 1'
      }, {
        id: 2,
        title: 'Connector 2'
      }, {
        id: 3,
        title: 'Connector 3'
      }, {
        id: 4,
        title: 'Connector 4'
      }]
    }
  },
  methods: {
    setActiveMenuItemId(id: JoinDataspaceStepInt) {
      this.activeStepId = id;
      console.log(id);
    }
  }
});
