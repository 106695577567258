import ApiVersion from '@/common/enums/ApiVersion';

export default class Util {
  public static VUE_APP_API_GATEWAY_URL = process.env.VUE_APP_API_GATEWAY_URL;
  /**
   * Get REST API URL
   * @param {string} url part to get full URL
   * @param {number} version Endpoint version
   * @returns {string} full REST API URL
  */
  static getRestApiUrl(url: string, version: number = ApiVersion.ONE) {
    return `${this.VUE_APP_API_GATEWAY_URL}/api/v${version}/${url}`;
  }

  /**
   * Create two way mapper Getter <-> Mutation for computed property
   * @param {string} getter
   * @param {string} mutation
   * @returns {any} Mapper for computed property
   */
   static mapTwoWay<T>(getter: string, mutation: string) {
    return {
      get(this: Vue): T {
        return this.$store.getters[getter];
      },
      set(this: Vue, value: T) {
        this.$store.commit(mutation, value);
      }
    };
  }
}