














import Vue from 'vue'
export default Vue.extend({
  name: 'PageHeading',
  props: {
    pageTitle: {
      default: '',
      type: String,
      required: false
    },
    isSubHeading: {
      default: '',
      required: false,
    }
  }
});
