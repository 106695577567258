import {Module} from 'vuex';
import {
  IS_ROLE_OFFER_CREATOR,
  IS_ROLE_OFFER_ERASER,
  IS_ROLE_OFFER_UPDATER,
  IS_ROLE_OFFER_READER,
  IS_ROLE_SOURCE_CREATOR,
  IS_ROLE_SOURCE_ERASER,
  IS_ROLE_SOURCE_UPDATER,
  IS_ROLE_SOURCE_READER,
  IS_ROLE_OBJECT_CREATOR,
  IS_ROLE_STORAGE_VIEWER,
  IS_ROLE_WORKSPACE_SOURCE_CREATOR,
  IS_ROLE_WORKSPACE_SOURCE_READER,
  IS_ROLE_WORKSPACE_SOURCE_ERASER,
  IS_ROLE_APPLICATIONS_READER,
  IS_ROLE_APPLICATIONS_ACTIVATOR,
  IS_ROLE_APPLICATIONS_DEACTIVATOR,
  IS_ROLE_APPLICATIONS_REQUEST,
  IS_ROLE_PROJECT_APPLICATIONS,
  IS_ROLE_PROJECT_READER,
  IS_ROLE_PROJECT_CREATOR,
  IS_ROLE_PROJECT_UPDATER,
  IS_ROLE_PROJECT_ERASER,
  IS_ROLE_OFFER_PURCHASER,
  IS_ROLE_MEMBERS_UPDATER,
  IS_ROLE_MEMBERS_ERASER,
  IS_ROLE_MEMBERS_CREATOR,
  IS_ROLE_MEMBERS_VIEWER,
  IS_ROLE_MANAGE_ORGANIZATION
} from './getter-types';
import MicroRole from '@/common/enums/MicroRole';
import {UPDATE_ROLES_SET} from './mutation-types';

export interface IRolesState {
  rolesSet: Set<MicroRole>;
}

const rolesState: Module<IRolesState, {}> = {
  state: {
    rolesSet: new Set<MicroRole>()
  },
  mutations: {
    [UPDATE_ROLES_SET](state, roles: MicroRole[]) {
      state.rolesSet = new Set<MicroRole>(roles);
    }
  },
  getters: {
    // storage
    [IS_ROLE_STORAGE_VIEWER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_STORAGE_VIEWER),
    [IS_ROLE_OBJECT_CREATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_OBJECT_CREATOR),
    // data sources
    [IS_ROLE_SOURCE_READER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATASOURCE_READER),
    [IS_ROLE_SOURCE_CREATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATASOURCE_CREATOR),
    [IS_ROLE_SOURCE_UPDATER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATASOURCE_UPDATER),
    [IS_ROLE_SOURCE_ERASER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATASOURCE_ERASER),
    // data offers
    [IS_ROLE_OFFER_READER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATAOFFER_READER),
    [IS_ROLE_OFFER_CREATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATAOFFER_CREATOR),
    [IS_ROLE_OFFER_UPDATER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATAOFFER_UPDATER),
    [IS_ROLE_OFFER_ERASER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATAOFFER_ERASER),
    // workspace sources
    [IS_ROLE_WORKSPACE_SOURCE_READER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_WORKSPACE_DS_READER),
    [IS_ROLE_WORKSPACE_SOURCE_CREATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_WORKSPACE_DS_CREATOR),
    [IS_ROLE_WORKSPACE_SOURCE_ERASER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_WORKSPACE_DS_ERASER),
    // workspace apps
    [IS_ROLE_APPLICATIONS_READER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_APPLICATIONS_READER),
    [IS_ROLE_APPLICATIONS_ACTIVATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_APPLICATIONS_ACTIVATOR),
    [IS_ROLE_APPLICATIONS_DEACTIVATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_APPLICATIONS_DEACTIVATOR),
    [IS_ROLE_PROJECT_APPLICATIONS]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_PROJECT_APPLICATIONS),
    [IS_ROLE_APPLICATIONS_REQUEST]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_APPLICATIONS_REQUEST),
    // workspace projects
    [IS_ROLE_PROJECT_READER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_PROJECT_READER),
    [IS_ROLE_PROJECT_CREATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_PROJECT_CREATOR),
    [IS_ROLE_PROJECT_UPDATER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_PROJECT_UPDATER),
    [IS_ROLE_PROJECT_ERASER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_PROJECT_ERASER),
    // organization
    [IS_ROLE_MANAGE_ORGANIZATION]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_MANAGE_ORGANIZATION),
    [IS_ROLE_MEMBERS_VIEWER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_MEMBERS_VIEWER),
    [IS_ROLE_MEMBERS_CREATOR]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_MEMBERS_CREATOR),
    [IS_ROLE_MEMBERS_ERASER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_MEMBERS_ERASER),
    [IS_ROLE_MEMBERS_UPDATER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_MEMBERS_UPDATE),
    // marketplace
    [IS_ROLE_OFFER_PURCHASER]: ({rolesSet}) => rolesSet.has(MicroRole.ROLE_DATAOFFER_PURCHASER)
  }
};

export default rolesState;
