
















































import Vue from 'vue';  
import { mapGetters } from 'vuex';
import {
  CURRENT_REALM_NAME,
  IS_FREEMIUM,
  IS_REGISTRATION_IN_PROGRESS,
  USERNAME
} from '@/store/modules/auth/getter-types';  
import {
  LOGOUT
} from '@/store/modules/auth/action-types';
import TenantIcon from '../TenantIcon/TenantIcon.vue';
export default Vue.extend({
  name: 'ContextSwitch',
  data() {
    return {
      isContextSwitchOpen: false
    };
  },
  components: {TenantIcon},
  computed: {
    ...mapGetters({
      username: USERNAME,
      isFreemium: IS_FREEMIUM
    }),
    currentRealmName(): string {
      return this.$store.getters[CURRENT_REALM_NAME];
    },      
    isRegistrationInProgress(): boolean {
      return this.$store.getters[IS_REGISTRATION_IN_PROGRESS];
    }
  },
  watch: {
    $route() {
      this.closeWidget();
    }
  },
  methods: {
    closeWidget() {
      if (this.isContextSwitchOpen) {
        this.isContextSwitchOpen = false;
      }
    },
    logout() {
      // TODO
      this.$store.dispatch(LOGOUT);
    },
    toggleWidget() {
      this.isContextSwitchOpen = !this.isContextSwitchOpen;
    }
  }
});
