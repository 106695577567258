enum MicroRole {
  // storage
  ROLE_STORAGE_VIEWER = 'ROLE_STORAGE_VIEWER',
  ROLE_OBJECT_CREATOR = 'ROLE_OBJECT_CREATOR',
  // data sources
  ROLE_DATASOURCE_READER = 'ROLE_DATASOURCE_READER',
  ROLE_DATASOURCE_CREATOR = 'ROLE_DATASOURCE_CREATOR',
  ROLE_DATASOURCE_UPDATER = 'ROLE_DATASOURCE_UPDATER',
  ROLE_DATASOURCE_ERASER = 'ROLE_DATASOURCE_ERASER',
  // data offers
  ROLE_DATAOFFER_READER = 'ROLE_DATAOFFER_READER',
  ROLE_DATAOFFER_CREATOR = 'ROLE_DATAOFFER_CREATOR',
  ROLE_DATAOFFER_UPDATER = 'ROLE_DATAOFFER_UPDATER',
  ROLE_DATAOFFER_ERASER = 'ROLE_DATAOFFER_ERASER',
  // workspace sources
  ROLE_WORKSPACE_DS_READER = 'ROLE_WORKSPACE_DS_READER',
  ROLE_WORKSPACE_DS_CREATOR = 'ROLE_WORKSPACE_DS_CREATOR',
  ROLE_WORKSPACE_DS_ERASER = 'ROLE_WORKSPACE_DS_ERASER',
  // workspace apps
  ROLE_APPLICATIONS_READER = 'ROLE_APPLICATIONS_READER',
  ROLE_APPLICATIONS_ACTIVATOR = 'ROLE_APPLICATIONS_ACTIVATOR',
  ROLE_APPLICATIONS_DEACTIVATOR = 'ROLE_APPLICATIONS_DEACTIVATOR',
  ROLE_APPLICATIONS_REQUEST = 'ROLE_APPLICATIONS_REQUEST',
  // workspace projects,
  ROLE_PROJECT_APPLICATIONS = 'ROLE_PROJECT_APPLICATIONS',
  ROLE_PROJECT_READER = 'ROLE_PROJECT_READER',
  ROLE_PROJECT_CREATOR = 'ROLE_PROJECT_CREATOR',
  ROLE_PROJECT_UPDATER = 'ROLE_PROJECT_UPDATER',
  ROLE_PROJECT_ERASER = 'ROLE_PROJECT_ERASER',
  // organization
  ROLE_MANAGE_ORGANIZATION = 'ROLE_MANAGE_ORGANIZATION',
  ROLE_MEMBERS_VIEWER = 'ROLE_MEMBERS_VIEWER',
  ROLE_MEMBERS_CREATOR = 'ROLE_MEMBERS_CREATOR',
  ROLE_MEMBERS_ERASER = 'ROLE_MEMBERS_ERASER',
  // TODO: wait for be remove typo and rename here
  ROLE_MEMBERS_UPDATE = 'ROLE_MEMBERS_UPDATE',
  // marketplace
  ROLE_DATAOFFER_PURCHASER = 'ROLE_DATAOFFER_PURCHASER'
}

export default MicroRole;
