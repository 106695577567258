export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const INIT_TOKEN = 'INIT_TOKEN';
export const INIT_PROFILE = 'INIT_PROFILE';
export const HANDLE_TOKEN_EXPIRY = 'HANDLE_TOKEN_EXPIRY';
export const GET_KEYCLOAK_REALM = 'GET_KEYCLOAK_REALM';
export const CHANGE_KEYCLOAK_REALM = 'CHANGE_KEYCLOAK_REALM';
export const REALM_LOGIN = 'REALM_LOGIN';
export const REALM_SWITCH = 'REALM_SWITCH';
