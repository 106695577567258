















import Vue from 'vue';
import {
  THeader
} from '@dih/dih-lib-frontend-component';
import PageFooter from '@/components/Footer/PageFooter.vue';
import SimpleNavigation from '@/components/Navigation/SimpleNavigation.vue';

export default Vue.extend({
  name: 'App',
  components: {
    THeader,
    PageFooter,
    SimpleNavigation
  }
})
