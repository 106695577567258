












































































import Vue from 'vue';
import TSection from '@/components/TSection/TSection.vue';
import PageHeading from '@/components/PageHeading/PageHeading.vue';
import DataspacesTile from '@/components/Tiles/DataspaceTile/DataspaceTile.vue';
import TEmptySection from '@/components/TSection/TEmptySection.vue';
// import TileIcon from '@/components/Tiles/TileIcon/TileIcon.vue';
import ToolsInfoModal from '@/components/CustomModals/ToolsInfoModals/ToolsInfoModal.vue';

interface IntDashboard {
  selectedTool: null | {};
} 

export default Vue.extend({
  name: 'DashboardView',
  components: {
    TSection,
    DataspacesTile,
    PageHeading,
    TEmptySection,
    // TileIcon,
    ToolsInfoModal
  },
  data(): IntDashboard {
    return {
      selectedTool: false
    }
  },
  methods: {
    onExitModal() {
      this.selectedTool = false;
    },
    orderConnector() {
      console.log("connectos");
    }
  }
})
