











import Vue from 'vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import SidebarComponent from '@/components/Sidebar/SidebarComponent.vue';

export default Vue.extend({
  name: 'DashboardLayout',
  components: {
    Navigation,
    SidebarComponent
  },
  data() {
    return {
      isSideBarCollapse: false
    }
  },
  methods: {
    onClickCollapse(payload: boolean): void {
      this.isSideBarCollapse = !payload;
    }
  }
})
