
















import Vue from 'vue'
export default Vue.extend({
  name: 'TEmptySection',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''  
    },
    btnString: {
      default: '',
      type: String
    }
  },
  methods: {
    onClickJoin() {
      this.$emit('onClickButton');
    }
  }  
})
