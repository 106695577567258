










































import Vue from 'vue';
import {
  TFooter
} from '@dih/dih-lib-frontend-component';

export default Vue.extend({
  components: {
    TFooter
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      locale: 'en'
    };
  },
  computed: {
    helpLinkAddress(): string {
      return this.locale === 'en' ?
        'https://dih.telekom.net/en/help/' :
        'https://dih.telekom.net/hilfe/';
    },
    imprintLinkAddress(): string {
      return this.locale === 'en' ?
        'https://dih.telekom.net/en/imprint/' :
        'https://dih.telekom.net/impressum/';
    },
    privacyLinkAddress(): string {
      return this.locale === 'en' ?
        'https://dih.telekom.net/en/privacy/' :
        'https://dih.telekom.net/datenschutzhinweise/';
    },
    termsAndConditionsLinkAddress(): string {
      return this.locale === 'en' ?
        'https://dih.telekom.net/en/terms-conditions/' :
        'https://dih.telekom.net/nutzungsbedingungen/';
    }
  },
  methods: {
    openCookieSettings() {
      // this.$store.commit(UPDATE_COOKIE_CONSENT_IS_OPEN, true);
    }
  }
});
