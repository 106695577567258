








import Vue from 'vue';
import {
  TAvatar
} from '@dih/dih-lib-frontend-component';
export default Vue.extend({
  name: 'TenantIcon',
  components: {
    TAvatar
  },
  props: {
    name: {
      required: true,
      type: String,
      default: ''
    }
  }
});
