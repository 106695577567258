






















import Vue from 'vue';
import {WizardItemStatus, IWizardItem} from '../IProcessModal';
import {
  TStatusIndicator
} from '@dih/dih-lib-frontend-component';

const StatusClassMap: {[k in WizardItemStatus]: string} = {
  [WizardItemStatus.PRISTINE]: '',
  [WizardItemStatus.COMPLETED]: 'is-success',
  [WizardItemStatus.INCOMPLETE]: 'is-warning',
  [WizardItemStatus.MISSING]: 'is-error'
};

export default Vue.extend({
  components: {
    TStatusIndicator
  },
  props: {
    item: {
      type: Object as () => IWizardItem,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusClass(): string {
      return StatusClassMap[this.item.status];
    }
  },
  methods: {
    select() {
      this.$emit('select');
    }
  }
});

