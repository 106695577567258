









































import Vue from 'vue';
import ContextNavItem from '@/components/ContextSwitch/ContextNavItem.vue';
import ContextSwitch from './ContextSwitch/ContextSwitch.vue';
import {
  TStatusIndicator
} from '@dih/dih-lib-frontend-component';

export default Vue.extend({
  name: 'NavigationComponent',
  components: {
    ContextSwitch,
    ContextNavItem,
    TStatusIndicator
  }
});
