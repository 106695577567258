import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
  sideMenuTranslation,
  topMenuTranslation
} from './global/MenuTranslation';
import {
  globalTextTranslation
} from './global/GlobalTranslation';

const i18nGlobalTranslation: VueI18n.LocaleMessages[] = [
  sideMenuTranslation,
  topMenuTranslation,
  globalTextTranslation
];

function getI18nMessages(): VueI18n.LocaleMessages {
  const messages: VueI18n.LocaleMessages = {};
  i18nGlobalTranslation.forEach((translation: VueI18n.LocaleMessages) => {
    const locales = Object.keys(translation);
    locales.forEach((locale: string) => {
      messages[locale] = messages[locale] ?
        {...messages[locale], ...translation[locale]} :
        {...translation[locale]};
    });
  });
  return messages;
}

function loadLocaleMessages(): VueI18n.LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: VueI18n.LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

Vue.use(VueI18n);

const messages = loadLocaleMessages();

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'de', // set fallback locale
    messages, // set locale messages
});

export default i18n;