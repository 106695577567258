export interface IWizardItem {
  id: string;
  title: string;
  status: WizardItemStatus;
  subtitle?: string;
}

export enum WizardItemStatus {
  COMPLETED = 'COMPLETED', // green - all mandatory and optional fields are filled out
  INCOMPLETE = 'INCOMPLETE', // yellow: all mandatory fields are filled out, some optional fields are not filled out
  MISSING = 'MISSING', // red - not all mandatory fields are filled out
  PRISTINE = 'PRISTINE' // grey - section not viewed yet, no validation applied
}
