












import Vue from 'vue';
import {
  TSelect
} from '@dih/dih-lib-frontend-component';

export default Vue.extend({
  components: {
    TSelect
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    emitValue(value: string) {
      this.$emit('input', value);
      console.log(value);
      console.log(this);
      this.$i18n.locale = value.toLocaleLowerCase();
    }
  }
});
