import {Module} from 'vuex';
import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';
import {
  manageOnboardingInt
} from './OrgOnboardingInt';

const INITIAL_STATE = {
  formFields: {
    orgName: '',
    orgAddress: {
      street: '',
      number: '',
      postCode: '',
      city: ''
    },
    orgTaxId: '',
    orgAdminFirstName: '',
    orgAdminLastName: '',
    orgAdminEmail: '',
    orgAdminCity: '',
    orgAdminPhoneNo: '',
    orgAdminRole: ''
  },
  isReqLoading: false
}

const manageOrgOnboardingState: Module<manageOnboardingInt, {}> = {
  state: INITIAL_STATE,
  actions,
  mutations,
  getters
};
  
  export default manageOrgOnboardingState;