export const SET_ORG_NAME = 'SET_ORG_NAME';
export const SET_ORG_ADDRESS_STREET = 'SET_ORG_ADDRESS_STREET';
export const SET_ORG_ADDRESS_NUMBER = 'SET_ORG_ADDRESS_NUMBER';
export const SET_ORG_ADDRESS_POSTCODE = 'SET_ORG_ADDRESS_POSTCODE';
export const SET_ORG_ADDRESS_CITY = 'SET_ORG_ADDRESS_CITY';
export const SET_ORG_ADDRESS_TAXID = 'SET_ORG_ADDRESS_TAXID';
export const SET_ORG_USER_FIRSTNAME = 'SET_ORG_USER_FIRSTNAME';
export const SET_ORG_USER_LASTNAME = 'SET_ORG_USER_LASTNAME';
export const SET_ORG_USER_EMAIL = 'SET_ORG_USER_EMAIL';
export const SET_ORG_USER_CITY = 'SET_ORG_USER_CITY';
export const SET_ORG_USER_PHONE_NO = 'SET_ORG_USER_PHONE_NO';
export const SET_ORG_USER_DEFINE_ROLE = 'SET_ORG_USER_DEFINE_ROLE';
export const SET_IS_PROGRESS = 'SET_IS_PROGRESS';