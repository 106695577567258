


























































import Vue from 'vue';
interface menuChildrenItem {
  title?: string;
  textKey?: string;
  iconClass?: string;
  path?: string;
  pathName?: string;
  isOpen?: boolean;
}
interface IntMenuItems {
  title: string;
  textKey?: string;
  iconClass: string;
  hasChild?: boolean;
  path?: string;
  pathName?: string;
  childrens?: menuChildrenItem[];
  isOpen: boolean;
}

interface SidebarInt {
  menuItems: IntMenuItems[]
}
export default Vue.extend({
  name: 'SidebarComponent',
  props: {
    menu: {
      type: Array as () => IntMenuItems[],
      required: false
    },
    isCollapse: {
      required: false,
      default: false
    }
  },
  data():SidebarInt  {
    return {
      menuItems: [{
        title: 'Dashboard',
        textKey: 'sideMenu.dashboard',
        iconClass: 'ticon-dashboard',
        hasChild: false,
        path: '/dashboard',
        pathName: 'maindashboard',
        isOpen: false
      }, {
        title: 'Connectors',
        textKey: 'sideMenu.connectors',
        iconClass: 'ticon-connectors',
        hasChild: true,
        path: '/connectors',
        pathName: 'connectors',
        isOpen: false,
        childrens: [{
          title: 'My Connectors',
          textKey: 'sideMenu.myConnectors',
          path: '/user',
          pathName: ''
        }]
      }, {
        title: 'Dataspaces',
        textKey: 'sideMenu.dataspaces',
        iconClass: 'ticon-dataspaces',
        hasChild: true,
        isOpen: false,
        childrens: [{
          title: 'My data spaces',
          textKey: 'sideMenu.myDataspaces',
          path: 'user',
          pathName: 'userDataspaces'
        }, {
          title: 'Browser data spaces',
          textKey: 'sideMenu.browseDataspaces',
          path: 'browse',
          pathName: 'browseDataspaces'
        }]
      }, {
        title: `${this.$t("sideMenu.organizations")}`,
        textKey: 'sideMenu.organizations',
        iconClass: 'ticon-organization',
        hasChild: false,
        isOpen: false,
        pathName: 'organizations'
      }]
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    onClickCollapse() {
      this.$emit('onClickCollapse', this.$props.isCollapse);
      // If menu collapse hide the expanded submenu
      if(!this.$props.isCollapse) {
        this.menuItems.forEach(item => {
          item.isOpen = false;
        });
      }
    },

    onClickMenuItem(menuItem: IntMenuItems, index: number, child?: menuChildrenItem): void {
      if(menuItem.hasChild) {
        this.menuItems[index].isOpen = !this.menuItems[index].isOpen;
      } else {
        if (this.$route.name !== menuItem.pathName)
        this.$router.push({ name: menuItem.pathName})
      }
      this.onClickMenuParentItem();
    },
    
    // check if menu is collape and expand it 
    onClickMenuParentItem() {
      if(this.$props.isCollapse) {
        this.$emit('onClickCollapse', this.$props.isCollapse)
      }
    }
  }
});
