import {ActionTree} from 'vuex';
import {IAuthState} from './index';
import {
  LOGIN,
  LOGOUT,
  CHECK_TOKEN,
  REFRESH_TOKEN,
  INIT_TOKEN,
  HANDLE_TOKEN_EXPIRY,
  INIT_PROFILE,
  GET_KEYCLOAK_REALM,
  CHANGE_KEYCLOAK_REALM,
  REALM_LOGIN,
  REALM_SWITCH,
  REGISTER
} from './action-types';
import {
  SET_AUTHORIZED,
  SET_UNAUTHORIZED,
  UPDATE_KEYCLOAK_REALM,
  UPDATE_USER_REGISTRATION_TYPE,
  UPDATE_USERNAME
} from './mutation-types';
import { UPDATE_ROLES_SET } from '@/store/modules/roles/mutation-types';
import AuthService from '@/common/services/AuthServices';
import {
  DEFAULT_KEYCLOAK_REALM
} from '@/common/constants';
import {
  KEYCLOAK_REALM
} from './getter-types';
import {
  IKeycloakRealm
} from '@/common/interfaces/auth/IToken';
// import {UPDATE_ROLES_SET} from '../roles/mutation-types';
import AccountType from '@/common/enums/AccountType';

let logoutTimeout: number;

const actions: ActionTree<IAuthState, {}> = {
  [LOGIN]() {
    return AuthService.keycloakLogin();
  },
  [LOGOUT]({getters, commit, dispatch}) {
    const keycloakRealm = getters[KEYCLOAK_REALM];
    commit(SET_UNAUTHORIZED);
    dispatch(CHANGE_KEYCLOAK_REALM, {
      lastVisited: keycloakRealm.current || DEFAULT_KEYCLOAK_REALM
    });
    AuthService.keycloakLogout();
  },
  [REGISTER](_context, type: AccountType) {
    AuthService.registerToRealm(DEFAULT_KEYCLOAK_REALM, type);
  },
  [GET_KEYCLOAK_REALM]({commit}) {
    commit(UPDATE_KEYCLOAK_REALM, AuthService.getKeycloakRealm());
  },
  [CHANGE_KEYCLOAK_REALM]({commit}, keycloakRealm: IKeycloakRealm) {
    commit(UPDATE_KEYCLOAK_REALM, keycloakRealm);
    AuthService.setKeycloakRealm(keycloakRealm);
  },
  [REALM_LOGIN]({dispatch}) {
    dispatch(CHANGE_KEYCLOAK_REALM, {current: AuthService.keycloakAuth.realm!, loginInProgress: true});
    dispatch(LOGIN);
  },
  [REALM_SWITCH]({getters, dispatch}, realmName: string) {
    if (realmName !== getters[KEYCLOAK_REALM].current) {
      dispatch(LOGOUT);
      dispatch(CHANGE_KEYCLOAK_REALM, {current: realmName, loginInProgress: true, switchRealm: true});
    }
  },
  [CHECK_TOKEN]({state, dispatch}, isLoginRequired?: boolean) {
    return new Promise((resolve, reject) => {
      if (state.token) {
        // Resolve if there's a token already and it's not a refresh request
        resolve(1);
      } else if (AuthService.keycloakAuth.idToken) {
        // Refresh otherwise via keycloak
        dispatch(REFRESH_TOKEN)
          .then(() => resolve(1))
          .catch(() => reject());
      } else {
        // Initialize token if it's not present yet
        dispatch(INIT_TOKEN, isLoginRequired)
          .then(() => resolve(1))
          .catch(() => reject());
      }
    });
  },
  [REFRESH_TOKEN]({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      AuthService.keycloakRefreshToken()
        .then(refreshed => {
          if (refreshed) {
            commit(SET_AUTHORIZED, AuthService.getToken());
          } else {
            console.log('Token is still valid');
          }
          resolve;
        })
        .catch(error => {
          // redirect to login when session is expired
          console.log('Failed to refresh the token, or the session has expired', error);
          dispatch(LOGIN);
          reject();
        });
    });
  },
  [INIT_TOKEN]({getters, commit, dispatch}, isLoginRequired?: boolean) {
    const keycloakRealm = getters[KEYCLOAK_REALM];
    return new Promise((resolve, reject) => {
      AuthService.setKeycloakOnTokenExpired(() => dispatch(HANDLE_TOKEN_EXPIRY));
      AuthService.keycloakInit()
        .then(() => {
          if (AuthService.keycloakAuth.authenticated) {
            dispatch(CHANGE_KEYCLOAK_REALM, {current: AuthService.keycloakAuth.realm!});
            // Set actual auth token
            commit(SET_AUTHORIZED, AuthService.getToken());
            dispatch(INIT_PROFILE)
              .then(resolve)
              .catch(reject);
          } else if (keycloakRealm.current && keycloakRealm.switchRealm) {
            // redirect to login when switching realm, set loginInProgress to true to allow user to go to open pages
            dispatch(CHANGE_KEYCLOAK_REALM, {current: keycloakRealm.current, loginInProgress: true});
            dispatch(LOGIN);
          } else if ((keycloakRealm.current && !keycloakRealm.loginInProgress) || isLoginRequired) {
            dispatch(LOGIN);
          } else if (keycloakRealm && keycloakRealm.loginInProgress) {
            // clean up due to hack with initialization but not login
            Object.keys(localStorage)
              .filter(key => /^kc-callback-[\da-fA-F]{8}-([\da-fA-F]{4}-){3}[\da-fA-F]{12}$/.test(key))
              .forEach(key => localStorage.removeItem(key));
            resolve(0);
          } else {
            resolve(0);
          }
        })
        .catch(error => {
          console.log('Error on keycloak init', error);
          dispatch(LOGIN);
          reject();
        });
    });
  },
  [HANDLE_TOKEN_EXPIRY]({state, dispatch}) {
    // clear logout timeout if it is present
    if (logoutTimeout) {
      window.clearTimeout(logoutTimeout);
    }
    const sessionExpiresIn = state.sessionExpiryTime - Date.now(); // ms
    if (sessionExpiresIn < 0) {
      // logout if session has expired
      console.log('Logging out');
      dispatch(LOGOUT);
    } else {
      // otherwise refresh token
      dispatch(REFRESH_TOKEN, true);
      // set timeout to logout when session expiry time is less then token expiry time
      if (sessionExpiresIn < AuthService.getToken().expiresIn * 1000) {
        logoutTimeout = window.setTimeout(() => dispatch(HANDLE_TOKEN_EXPIRY), sessionExpiresIn);
      }
    }
  },
  [INIT_PROFILE]({commit}) {
    // Request keycloak profile with username
    return AuthService.getKeycloakProfile()
      .then(({username, attributes: {registrationType}}) => {
        // Set user registration type. Undefined means that user completed his registration flow
        const type = registrationType ? registrationType[0] : undefined;
        commit(UPDATE_USER_REGISTRATION_TYPE, type);
        commit(UPDATE_USERNAME, username);
        // Request keycloak roles associated to user
        commit(UPDATE_ROLES_SET, AuthService.getResourceUserRoles());
        // return dispatch(INIT_USER_PROFILE);
      });
  }
};

export default actions;
